import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'
import SEO from 'components/SEO'

import s from './index.module.css'

export default function Stockists() {
  return (
    <Layout className={s.container}>
      <SEO title="Find your nearest stockist | Store Locator | Chalk UK" />
      <div className={s.header}>
        <h1 className={s.title}>
          Shop local
          <br />
          Shop at independents
        </h1>
        <Link to="/stockists/locator" className={s.link}>
          Find a stockist
        </Link>
      </div>
      <StaticImage
        src="../../assets/wholesale.jpg"
        className={s.img}
        alt="Click above to view our stockists"
        placeholder="NONE"
        backgroundColor="#eee"
      />
    </Layout>
  )
}
